.slide.special {
    @media (max-width: 1024px) {
        padding-top: 49px;
        background-position: 92% 15%;
    }

    @media (min-width: 1024px) {
        padding-top: 58px;
        background-size: 60%;
    }

    @media (min-width: 1440px) {
        padding-top: 86px;
    }

    @media (min-width: 1920px) {
        padding-top: 100px;
    }
}
.slide {
    z-index: 1;
}
.sliderWrapper {
    position: relative;
}

.specialImage {
    position: absolute;

    height: auto;
    z-index: 0;

    @media (max-width: 410px) {
        max-width: 460px !important;
        left: unset;
        top: -68px;
        right: -168px;
    }
    @media (min-width: 410px) {
        max-width: 460px !important;
        left: 150px;
        top: -95px;
        right: unset;
    }
    @media (min-width: 768px) {
        max-width: 377px !important;
        top: -65px;
        left: 343px;
    }
    @media (min-width: 1024px) {
        max-width: 595px !important;
        left: unset;
        top: -147px;
        right: -11px;
    }
    @media (min-width: 1440px) {
        max-width: 715px !important;
        top: -139px;
        right: -11px;
    }
    @media (min-width: 1920px) {
        max-width: 814px !important;
        top: -158px;
        right: -60px;
    }
}
