.carousel-slider__wrapper {
    overflow: auto;
    max-width: 100vw;
    position: relative;
    z-index: 2;
}

.carousel-slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;

    &:focus-visible {
        outline: none;
    }
}

.carousel-slider__track {
    display: flex;
    flex-wrap: nowrap;
    height: auto;
    touch-action: pan-y;
}

.carousel-slider__slide {
    min-width: 100%;
    max-width: 0;
    height: 100%;
}

.carousel-slider__slide--clickable {
    cursor: pointer;
}
