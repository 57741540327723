@import 'src/styles';

.section-title {
    font: var(--font-h2-wide);
    color: var(--color-white);
    margin-bottom: 28px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

@include mq-tablet-768-up {
    .section-title {
        margin-bottom: var(--margin-small);
    }
}

@include mq-desktop-1440-up {
    .section-title {
        font: var(--font-h1-wide);
        margin-bottom: var(--margin-basic);
    }
}
