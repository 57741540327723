@import 'src/styles';

@mixin arrow-small {
    padding: 10px;
    width: fit-content;
    height: fit-content;

    svg {
        font-size: 20px;
    }
}

@mixin arrow-medium {
    padding: 16px;
    width: fit-content;
    height: fit-content;

    svg {
        font-size: 35px;
    }
}

@mixin arrow-large {
    padding: 24px;
    width: fit-content;
    height: fit-content;

    svg {
        font-size: 45px;
    }
}

.last-slide {
    width: auto;
    height: auto;
    background-color: var(--color-shark);
    border-radius: 16px;
    display: flex;
    position: relative;

    cursor: pointer;

    &--verticalBig {
        width: var(--width-vertical-big);
        height: var(--height-vertical-big);
    }

    &--horizontBig {
        width: var(--width-horizontal-big);
        height: var(--height-horizontal-big);
    }

    &--horizontSmall {
        width: var(--width-horizontal-small);
        height: var(--height-horizontal-small);
    }
}

.last-slide__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.last-slide__arrow-circle {
    border-radius: 50%;
    background-color: rgba(#626c77, 0.25);

    svg {
        width: 1em;
        height: 1em;
    }

    &--verticalBig {
        @include arrow-large;
    }

    &--horizontBig {
        @include arrow-large;
    }

    &--horizontSmall {
        @include arrow-small;
    }
}

.last-slide__title {
    text-align: center;

    &--verticalBig {
        margin: 32px 20px 0;
    }

    &--horizontBig {
        margin: 16px 20px 0;
    }

    &--horizontSmall {
        margin: 8px 8px 0;
        font: var(--font-p2-regular-comp);
    }
}

@include mq-phone-only {
    .last-slide__arrow-circle {
        &--horizontBig {
            @include arrow-medium;
        }

        &--horizontSmall {
            @include arrow-medium;
        }
    }

    .last-slide__title {
        max-width: 160px;

        &--horizontBig {
            margin: 8px 8px 0;

            font: var(--font-p2-regular-comp);
        }
    }
}

@include mq-tablet-768-up {
    .last-slide__arrow-circle {
        &--horizontBig {
            @include arrow-medium;
        }
    }

    .last-slide__title {
        &--horizontBig {
            margin: 8px 20px 0;
        }

        &--horizontSmall {
            display: none !important;
        }
    }
}

@include mq-desktop-1440-up {
    .last-slide__arrow-circle {
        &--horizontBig {
            @include arrow-large;
        }

        &--horizontSmall {
            @include arrow-medium;
        }
    }

    .last-slide__title {
        max-width: 250px;

        &--horizontBig {
            margin: 16px 20px 0;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .last-slide--verticalBig {
        aspect-ratio: 202 / 260;
    }

    .last-slide--horizontBig {
        aspect-ratio: 280 / 162;
    }
}
