@import 'src/styles';

.page-background__next {
    position: absolute;
    width: 100%;
    height: 834px;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    filter: blur(100px);
}