@use "sass-rem" as rem;

.carousel-main {
    width: 100%;
}

.carousel-main__carousel-grid {
    display: grid;
    grid-template-columns: 1fr 31.8%;
    grid-template-areas: 'main' 'side';

    @media (min-width: 1680px) {
        grid-template-columns: 1fr 1fr;
    }
}

.carousel-main__slider {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.carousel-main__each-thumbnail-wrapper {
    height: rem.convert(80px);
    width: 100%;
    display: block;
    cursor: pointer;
}
.carousel-main__slides-switcher {
    width: 100%;
    margin-top: rem.convert(24px);
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.carousel-main__switch-point {
    margin-right: rem.convert(20px);

    width: 10px;
    height: 10px;
    background-color: rgba(127, 140, 153, 0.35);
    border-radius: 50%;
    transition: background-color 225ms;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}
.carousel-main__switch-point--active {
    background-color: var(--color-mts-company);
}

.carousel-main-new {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 31.8%;
    column-gap: 1.4rem;
}

.carousel-main-new__big-card-container {
    flex-grow: 1;
    height: rem.convert(407px);
}

.carousel-main-new__big-card-container > * {
    height: 100%;
}

.carousel-main-new__aside-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    gap: 1.4rem;

}
.carousel-main-new__thumbnail-wrapper {
    width: 100%;
}

.slides-switcher {
    width: 100%;
    margin-top: rem.convert(24px);
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.slides-switcher__point {
    margin-right: rem.convert(20px);

    width: 10px;
    height: 10px;
    background-color: rgba(127, 140, 153, 0.35);
    border-radius: 50%;
    transition: background-color 225ms;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}
.slides-switcher__point--active {
    background-color: var(--color-mts-company);
}