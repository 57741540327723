@use "sass-rem" as rem;
@import 'src/styles';

.filters-component {
    display: none;
    flex-direction: column;
    width: 100%;
    top: 0;
    z-index: 10;
    padding: 0 1.25rem;
    box-sizing: border-box;
    background-color: transparent;
    padding: 0;
}

.filters-component__filters {
    position: relative;
    display: grid;
    grid-template-columns: rem.convert(1fr 1fr 1fr);
    column-gap: 32px;
    row-gap: 16px;
    width: 100%;
    top: 0;
    z-index: 10;
    padding: 0 1.25rem;
    box-sizing: border-box;
    background-color: transparent;
    padding: 0;
}

.filters-component--main-page .filters-component__filters {
    grid-template-columns: rem.convert(1fr 1fr 1fr 1fr);
    column-gap: 32px;
}

.filters-component__tags {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin: 32px 0;
    flex-wrap: wrap;
}

.filters-component__sort-indicators {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    color: var(--color-chateau);
    font: var(--font-p2-regular-comp);
}

.filters-component__additional-filters {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;
}

.filters-component__manage-button {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;

    .filters-component__red-indicator {
        width: rem.convert(20px);
        height: rem.convert(20px);
        border-radius: 50%;
        position: absolute;
        background-color: var(--color-mts-company);
        top: rem.convert(5px);
        left: rem.convert(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-white);
        font: var(--font-caption-regular-comp);
    }
}

.filters-component--mobile {
    display: block;
    visibility: visible;
    .filters-component__manage-button {
        width: 100%;
        gap: 8px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }
    .filters-component__red-indicator {
        position: relative;
        top: 0;
        left: 0;
    }
}

.filters-component__filters-counter {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    font: var(--font-caption-medium-comp);
}

@include mq-tablet-768-up {
    .filters-component {
        display: flex;
    }

    .filters-component__filters {
        column-gap: 21px;
    }
}

@include mq-desktop-1024-up {
    .filters-component__filters {
        grid-template-columns: rem.convert(1fr 1fr 1fr 1fr 44px);
        column-gap: 32px;
    }

    .filters-component__manage-button {
        display: flex;
    }

    .filters-component__additional-filters {
        justify-content: flex-end;
    }
}
