@import 'src/styles';

.large-banner {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    text-align: center;
    cursor: pointer;
    position: relative;
}
.large-banner__title {
    position: absolute;
    color: var(--color-alabaster);
    font: var(--font-h3-wide);
}

@include mq-phone-only {
    .large-banner {
        overflow: unset;
    }

    .large-banner__title {
        font: var(--font-h4-wide);
        padding: 0;
        bottom: -44px;
        background: unset;
    }
}
@include mq-tablet-768-up {
    .large-banner {
        overflow: hidden;
    }

    .large-banner__title {
        bottom: 0;
        width: 100%;
        text-align: start;
        padding: 0 32px 40px;
        background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.6) 60%);
        font: var(--font-h3-wide);
    }
}
@include mq-desktop-1024-up {
    .large-banner__title {
        font: var(--font-h2-wide);
    }
}
