@use "sass-rem" as rem;
@import 'src/styles';

.game-card-large {
    --padding-game-details: 0px 32px 40px;
    --margin-platforms: 0 0 16px;

    width: 100%;
    height: 100%;
    cursor: pointer;

    a {
        text-decoration: none;
    }
}

.game-card-large__tag-wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    padding: rem.convert(70px 20px 0);
    height: 100%;
}

.game-card-large__slide-content {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.game-card-large__game-details {
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.6) 60%);
    padding: var(--padding-game-details);
    color: var(--color-white);
    font: var(--font-h3-wide);
}

.game-card-large__info {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
}

.game-card-large__price-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: inherit;
    flex-wrap: wrap;
    text-align: right;
}

.game-card-large__offer-promo-remains {
    color: var(--color-text-dark-secondary);
    font: var(--font-p3-regular-comp);
    max-width: max-content;
    text-align: left;
    white-space: nowrap;
}

.game-card-large__offer-promo-remains--day {
    background: var(--color-discount-back);
    color: var(--color-white);
    padding: 2px 8px;
    border-radius: 16px;
}

.game-card-large__info.game-card-large__info--mobile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0 0 0;
    gap: 20px;
    font: var(--font-h3-wide);
}

.game-card-large__game-title {
    color: var(--color-alabaster);
}

.game-card-large__platforms-tags {
    position: absolute;
    top: 24px;
    left: 32px;
    align-items: flex-end;
    display: flex;
    margin: var(--margin-platforms);

    .game-card-large__tag {
        background-color: var(--color-white);
        margin-right: rem.convert(8px);
        border-radius: rem.convert(8px);
        padding: rem.convert(10px);
    }
}

@include mq-phone-only {
    .game-card-large {
        --padding-game-details: 16px;
        --margin-platforms: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    .game-card-large__tag-wrapper {
        padding: rem.convert(16px 0 0);
    }

    .game-card-large__info {
        display: none;
    }

    .game-card-large__platforms-tags {
        top: 16px;
        left: 16px;
    }
}

@include mq-tablet-768-up {
    .game-card-large__info.game-card-large__info--mobile {
        display: none;
    }
}

@include mq-desktop-1440-up {
    .game-card-large__game-details {
        font: var(--font-h2-wide);
    }
}
