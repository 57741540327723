@use "sass-rem" as rem;

.price {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 767px) {
        --discount-font: var(--font-p3-medium-comp);
        --starting-price-font: var(--font-p1-regular-comp);
        --final-price-font: var(--font-h3-comp);
        --days-left-font: var(--font-p3-regular-comp);
    }

    @media screen and (min-width: 768px) {
        --discount-font: var(--font-p3-medium-comp);
        --starting-price-font: var(--font-p2-regular-comp);
        --final-price-font: var(--font-p2-medium-comp);
        --days-left-font: var(--font-p3-regular-comp);
    }

    @media screen and (min-width: 1440px) {
        --discount-font: var(--font-p3-medium-comp);
        --starting-price-font: var(--font-p1-regular-comp);
        --final-price-font: var(--font-h3-comp);
        --days-left-font: var(--font-p3-regular-comp);
    }
}

.price--small {
    min-height: 32px;
}

.price--card {
    --gap-between-prices: 16px;
}

.price--card-large {
    --gap-between-prices: 16px;
    @media screen and (min-width: 1920px) {
    }

    @media screen and (max-width: 767px) {
        --discount-font: var(--font-p3-regular-comp);
        --final-price-font: var(--font-h3-comp);
    }
    @media screen and (min-width: 768px) {
        --discount-font: var(--font-p3-regular-comp);
        --starting-price-font: var(--font-p1-regular-comp);
        --final-price-font: var(--font-h3-comp);
    }
}

.price--offer {
    @media screen and (max-width: 767px) {
        --gap-between-prices: 12px;
        --discount-font: var(--font-p1-regular-comp);
        --starting-price-font: var(--font-p1-regular-comp);
        --final-price-font: var(--font-h3-comp);
    }
    @media screen and (min-width: 768px) {
        --gap-between-prices: 8px;
        --discount-font: var(--font-p3-regular-comp);
        --starting-price-font: var(--font-p2-regular-comp);
        --final-price-font: var(--font-p2-medium-comp);
    }
    @media screen and (min-width: 1024px) {
        --gap-between-prices: 16px;
        --discount-font: var(--font-p3-regular-comp);
        --starting-price-font: var(--font-p1-regular-comp);
        --final-price-font: var(--font-h3-comp);
    }
    @media screen and (min-width: 1440px) {
        --gap-between-prices: 16px;
        --discount-font: var(--font-p2-regular-comp);
        --starting-price-font: var(--font-h2-comp);
        --final-price-font: var(--font-h2-comp);
    }
}

.price__prices-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: var(--gap-between-prices);
}

.price__discount {
    white-space: nowrap;
    font: var(--discount-font);
    color: var(--color-white);
    padding: rem.convert(4px 8px);
    background: var(--color-discount-back);
    border-radius: rem.convert(8px);
}

.price__starting-price {
    font: var(--starting-price-font);
    color: var(--color-text-dark-secondary);
    position: relative;
    white-space: nowrap;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 0;
        border-bottom: 2px solid var(--color-gray);
        transform: rotate(-11deg);
    }
}

.price__final-price {
    white-space: nowrap;
    font: var(--final-price-font);
    color: var(--color-white);
}

.price__days-left {
    white-space: nowrap;
    font: var(--days-left-font);
    color: var(--color-text-dark-secondary);
}

.price__out-of-stock {
    font: var(--font-p2-regular-comp);
}
