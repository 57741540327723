@import 'src/styles';

.news-slide {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 16px;
}

.news-slide__tag-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 32px;
    width: 100%;
    position: absolute;
    z-index: 3;
}
.news-slide__activation-tags {
    display: flex;
    gap: 8px;
}

.news-slide__image {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(#626c77, 0.25);
    position: absolute;
}

.news-slide__title-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 3;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px 40px;
    align-items: self-end;
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.6) 60%);
}
.news-slide__title {
    font-family: 'MTSWideMedium';
    color: var(--color-alabaster);
}

.news-slide__date {
    font: var(--font-p2-regular-comp);
    color: var(--color-chateau);
}
.news-slide__type {
    width: max-content !important;
}

@include mq-phone-only {
    .news-slide__title {
        font: var(--font-h4-wide);
    }
    .news-slide__mobile-wrapper {
        display: block;
        margin-top: 20px;
    }
    .news-slide__mobile-wrapper .news-slide__title-row {
        position: relative;
        flex-direction: column;
        all: unset;
        gap: 8px;
    }
    .news-slide__top-wrapper {
        position: relative;
    }
    .news-slide__top-wrapper .news-slide__title-row {
        display: none;
    }
    .news-slide__mobile-wrapper .news-slide__date {
        display: block;
        margin-top: 8px;
    }
    .news-slide__tag-row {
        box-sizing: border-box;
        padding: 12px;
        flex-direction: column;
        height: 100%;
    }
}
@include mq-tablet-768-up {
    .news-slide__title {
        font: var(--font-h3-wide);
    }
    .news-slide__mobile-wrapper {
        display: none;
    }
    .news-slide__top-wrapper .news-slide__title-row {
        display: flex;
    }
}
@include mq-desktop-1024-up {
    .news-slide__title {
        font: var(--font-h2-wide);
    }
}
