$arrow-width: 56px;
$arrow-height: 56px;
$arrow-width-small: 25px;
$arrow-height-small: 25px;

.slider {
    z-index: 10;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;

    &:focus-visible {
        outline: none;
    }
}

.slider__track {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    touch-action: pan-y;
}

.slider__slide {
    cursor: pointer;

    & img {
        pointer-events: none;
    }
}

.slider__arrow {
    top: 0;
    
    position: absolute;
    cursor: pointer;

    z-index: 10; // Must be lower than favorites-btn 

    &::before {
        position: absolute;
        content: '';
        width: $arrow-width;
        height: $arrow-height;
        background: url('../../../../../public/images/icons/arrow-slider-updated.svg') no-repeat;
    }
}


.slider__arrow--verticalBig {
    width: calc(var(--width-vertical-big) * 0.66);
    height: var(--height-vertical-big);
}

.slider__arrow--horizontBig {
    width: calc(var(--width-horizontal-big) * 0.66);
    height: var(--height-horizontal-big);
}

.slider__arrow--horizontSmall {
    width: $arrow-width-small;
    height: $arrow-height-small;

    width: calc(var(--width-horizontal-small) * 0.66);
    height: var(--height-horizontal-small);
    &::before {
        width: $arrow-width-small;
        height: $arrow-height-small;
        background-size: 100%;
    }
}

.slider__arrow--prev {
    left: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    background: linear-gradient(90deg, #000000 7.12%, rgba(0, 0, 0, 0.568238) 73.21%, rgba(0, 0, 0, 0) 102.35%);


    &::before {
        left: 0;
        transform: rotate(180deg);
    }
}

.slider__arrow--next {
    right: 0;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    background: linear-gradient(270.42deg, #000000 7.12%, rgba(0, 0, 0, 0.568238) 73.21%, rgba(0, 0, 0, 0) 102.35%);

    &::before {
        right: 0;
    }
}

.slider__arrow--verticalBig.slider__arrow--prev::before,
.slider__arrow--verticalBig.slider__arrow--next::before {
    top: calc((var(--height-vertical-big) - $arrow-height) / 2);
}
.slider__arrow--horizontBig.slider__arrow--prev::before,
.slider__arrow--horizontBig.slider__arrow--next::before {
    top: calc((var(--height-horizontal-big) - $arrow-height) / 2);
}
.slider__arrow--horizontSmall.slider__arrow--prev::before,
.slider__arrow--horizontSmall.slider__arrow--next::before {
    top: calc((var(--height-horizontal-small) - $arrow-height-small) / 2);
}

.slider__arrow--disabled {
    cursor: default;
    &::before {
        opacity: 0.25;
    }
}
