.slider {
    @media (max-width: 1023px) {
        margin-right: -40px;
    }
    @media (max-width: 767px) {
        margin-right: -20px;
    }
    @media (min-width: 1440px) {
        margin: 12px 0;
    }
}

.horizontBig .arrowPrev,
.horizontBig .arrowPrev::after,
.horizontBig .arrowNext,
.horizontBig .arrowNext::after {
    height: 100%;
}

.slider__main-slide.verticalBig,
.slider__main-slide.horizontBig,
.slider__main-slide.horizontSmall {
    width: auto;
}

.verticalBig .arrowPrev,
.verticalBig .arrowPrev::after,
.verticalBig .arrowNext,
.verticalBig .arrowNext::after {
    height: 100%;
}

.horizontSmall .arrowPrev {
    display: none;
}

.horizontSmall .arrowNext {
    display: none;
}
